@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

body {
  margin: 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', antialiased;
  -moz-osx-font-smoothing: grayscale;
}